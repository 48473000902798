<template>
    <v-app>
        <div class="flex-row-fluid d-flex flex-column position-relative p-14 overflow-hidden">
            <div class="d-flex flex-column-fluid flex-center">
                <div class="d-flex flex-column ">
                    <h1 class="mb-5">Thank your for your payment</h1>
                    <div class="col-md-12 mb-10 text-center">
                        <img src="/media/payments/success.png" style="width: 95px; text-align: center"/>
                    </div>
                    <p>We have received your payment and your application submission is now complete. Please check your email for a copy of your admit card. Alternatively, you can download them using the links below.</p>
                    <div class="col-md-12 text-center">
<!--                        <a class="title download_success" @click="downloadInvoice"><i style=" font-size: 22px;" class="fas fa-file-pdf"></i> &nbsp;Download invoice</a>-->
                        <a class="title download_success" @click="downloadAdmitCard"><i style=" font-size: 22px;" class="fas fa-id-badge"></i> &nbsp;Download admit card</a>
                    </div>
                    <div class="col-md-12 text-left">
                        <a v-if="app_url" :href="app_url" class="mr-5"><i style=" font-size: 22px;" class="fas fa-home"></i> &nbsp; Visit main website</a>
                        | <a @click="goToHome" class="ml-5"><i style=" font-size: 22px;" class="fas fa-refresh"></i> &nbsp; New registration</a>
                    </div>
                </div>
            </div>
        </div>
    </v-app>
</template>
<script>
import OptionService from "@/core/services/option/OptionService";
import EssentialService from "@/core/services/enrollment/EssentialService";
const option=new OptionService();
const enrollmentEssentialService=new EssentialService();
export default {
    data(){
        return{
            app_url:null,
        }
    },
    mounted() {
        if (localStorage.hasOwnProperty('_newToken')) {
            localStorage.removeItem('_newToken');
        }

        if(this.applicationId){
            this.getByAppUrl();
        }else{
            this.$router.push({name: 'index'})
        }

    },
    methods:{
        downloadInvoice(){
            if(this.applicationId){
                enrollmentEssentialService
                    .downloadUserEnrollmentInvoice(this.applicationId)
            }
        },
        downloadAdmitCard() {
            if(this.applicationId){
                enrollmentEssentialService
                    .downloadUserEnrollmentAdmitCard(this.applicationId)
            }
        },
        goToHome(){
            this.$router.push({name: 'pre-registration'});
        },
        getByAppUrl(){
            option
                .getByKey('APP_URL')
                .then(response => {
                    if(response.data){
                        this.app_url = response.data.value;
                    }


                }).catch((err) => {
            }).finally(() => {


            });
        },
    },
    computed:{
        applicationId() {
            return this.$route.query.application_id;
        },
    }
}
</script>
<style scoped>
.payment-content {
    text-align: center;
    padding: 170px 0;
    background: #EBF0F5;
    max-height: 100%;

}
h1 {
    color: #88B04B;
    font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
    font-weight: 900;
    font-size: 40px;
    margin-bottom: 10px;
}
p {
    color: #404F5E;
    font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
    font-size:20px;
    margin: 0;
}
i {
    color: #9ABC66;
    font-size: 100px;
    line-height: 200px;
    margin-left:-15px;
}
.card {
    background: white;
    padding: 60px;
    border-radius: 4px;
    box-shadow: 0 2px 3px #C8D0D8;
    display: inline-block;
    margin: 0 auto;
}
</style>
