import Apiservice from '@/core/services/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;


export default class OptionService {
    #api = null;

    constructor() {
        this.#api = `${API_URL}api/admission/option`;
    }

    getByKey(key) {
        let url = `${this.#api}/${key}`;
        return apiService.get(url);
    }

    convertToAd(date) {
        let url = `${this.#api}/convert/to-ad?date=${date}`;
        return apiService.get(url);
    }

    convertToBs(date) {
        let url = `${this.#api}/convert/to-bs?date=${date}`;
        return apiService.get(url);
    }

    getByKeys(data) {
        let url = `${this.#api}/get-by-keys/all`;
        return apiService.post(url,data);
    }
}
