import Apiservice from '@/core/services/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;


export default class EssentialService {

    #api = null;
    token = null;

    constructor() {
        this.token = localStorage.getItem('tk');
        this.#api = `${API_URL}api/admission/${this.token}/`;
    }

    setToken() {
        this.token = localStorage.getItem('tk');
        this.#api = `${API_URL}api/admission/${this.token}/`;
    }
    setToken2(token) {
        this.#api = `${API_URL}api/admission/${token}/`;
    }

    allLevels() {
        this.setToken();
        let url = `${this.#api}levels`
        return apiService.get(url)
    }

    getAllPreRegistrationLevels() {
        this.setToken();
        let url = `${API_URL}api/admission/pre-registration-levels`
        return apiService.get(url)
    }




    getAllPreRegistrationPrograms(level) {
        this.setToken();
        let url = `${API_URL}api/admission/pre-registration-program/${level}/level`
        return apiService.get(url)
    }

    getAllPreRegistrationDetail(level, program) {
        this.setToken();
        // http://api.kist.stage/api/admission/pre-registration-level/+2/program/+2%20science
        let url = `${API_URL}api/admission/pre-registration-level/${level}/program/${program}`
        return apiService.get(url)
    }

    getProgramDetail(level, program) {
        this.setToken();
        // http://kist.stage/api/admission/sdfsdf/program/Bachelor of Information Technology/program-detail
        let url = `${API_URL}api/admission/${level}/program/${program}/program-detail`
        return apiService.get(url)
    }

    showLevel(id) {
        this.setToken();
        let url = `${this.#api}level/${id}`
        return apiService.get(url)
    }

    getProgramByLevelId(levelId) {
        this.setToken();
        let url = `${this.#api}level/${levelId}/programs`
        return apiService.get(url)
    }

    showProgram(programId) {
        this.setToken();
        let url = `${this.#api}program/${programId}`
        return apiService.get(url)
    }

    convertAdToBs(data) {
        this.setToken();
        let url = `${this.#api}convert/ad-bs`
        return apiService.post(url, data);
    }

    provinces() {
        this.setToken();
        let url = `${this.#api}provinces`
        return apiService.get(url);
    }

    districtsByProvince(provinceId) {
        this.setToken();
        let url = `${this.#api}province/${provinceId}/districts`
        return apiService.get(url);
    }

    municipalitiesByDistrict(districtId) {
        this.setToken();
        let url = `${this.#api}district/${districtId}/municipalities`
        return apiService.get(url);
    }

    getInstitutions() {
        this.setToken();
        let url = `${this.#api}institutions`
        return apiService.get(url);
    }

    getEducations() {
        this.setToken();
        let url = `${this.#api}educations`
        return apiService.get(url);
    }

    getEducationByLevel(levelId) {
        this.setToken();
        let url = `${this.#api}education/${levelId}/all`
        return apiService.get(url);
    }

    userEducation(userId) {
        this.setToken();
        let url = `${this.#api}${userId}/user-educations`;
        return apiService.get(url);
    }

    userInfo(userId) {
        this.setToken();
        let url = `${this.#api}${userId}/user-info`;
        return apiService.get(url);
    }

    questionnaireByProgram(programId) {
        this.setToken();
        let url = `${this.#api}questionnaire/${programId}/by-program`;
        return apiService.get(url);
    }

    userDocuments(userId) {
        this.setToken();
        let url = `${this.#api}enrollment/${userId}/documents`;
        return apiService.get(url);
    }

    entranceDatesByProgram(programId) {
        this.setToken();
        let url = `${this.#api}entrance-dates/${programId}/by-program`;
        return apiService.get(url);
    }

    /*******************Enrollment*****************************/
    getInCompleteEnrollment(enrollmentId) {
        let url = `${this.#api}incomplete-enrollment/${enrollmentId}`;
        return apiService.get(url);
    }

    getEnrollmentByToken(applicationId) {
        localStorage.setItem('tk', applicationId)
        this.setToken();
        let url = `${this.#api}enrollment/${applicationId}`;
        return apiService.get(url);
    }

    storeEnrollment(data,token=null) {
        if(token){
            this.setToken2(token);
        }else{
            this.setToken();
        }

        let url = `${this.#api}enrollment`;
        return apiService.post(url, data);
    }

    updateEnrollment(id, data) {
        this.setToken();
        let url = `${this.#api}enrollment/${id}/update`;
        return apiService.put(url, data);
    }
    getAdmissionFee(id){
        if(id){
            this.setToken2(id);
        }else{
            this.setToken();
        }
        let url = `${this.#api}enrollment/get-admission-fee`;
        return apiService.get(url);
    }
    getUniqueProductCode(id){
        if(id){
            this.setToken2(id);
        }else{
            this.setToken();
        }
        let url = `${this.#api}enrollment/get-unique-product-code`;
        return apiService.get(url);
    }

    getHistoryByType(value, medium, registrationType) {
        this.setToken();
        let url = `${this.#api}enrollments/${value}/by/medium/${medium}/registration-type/${registrationType}`;
        return apiService.get(url);
    }

    /*******************Enrollment End*****************************/

    /*******************Address*****************************/
    getAddressesByEnrollment(enrollmentId) {
        this.setToken();
        let url = `${this.#api}enrollment/${enrollmentId}/addresses`;
        return apiService.get(url);
    }

    saveAddress(enrollmentId, data) {
        this.setToken();
        let url = `${this.#api}enrollment/${enrollmentId}/address`;
        return apiService.post(url, data);
    }

    updateAddress(enrollmentId, id, data) {
        this.setToken();
        let url = `${this.#api}enrollment/${enrollmentId}/address/${id}/update`;
        return apiService.put(url, data);
    }

    /*******************Address End*****************************/

    /*******************Parent*****************************/
    getParentsByEnrollment(enrollmentId) {
        this.setToken();
        let url = `${this.#api}enrollment/${enrollmentId}/parents`;
        return apiService.get(url);
    }

    saveParent(enrollmentId, data) {
        this.setToken();
        let url = `${this.#api}enrollment/${enrollmentId}/parent`;
        return apiService.post(url, data);
    }

    updateParent(enrollmentId, id, data) {
        this.setToken();
        let url = `${this.#api}enrollment/${enrollmentId}/parent/${id}/update`;
        return apiService.put(url, data);
    }

    /*******************Address End*****************************/

    /*******************Education*****************************/
    getEducationsByEnrollment(enrollmentId) {
        this.setToken();
        let url = `${this.#api}enrollment/${enrollmentId}/educations`;
        return apiService.get(url);
    }

    saveEducation(enrollmentId, data) {
        this.setToken();
        let url = `${this.#api}enrollment/${enrollmentId}/education`;
        return apiService.post(url, data);
    }

    updateEducation(enrollmentId, id, data) {
        this.setToken();
        let url = `${this.#api}enrollment/${enrollmentId}/education/${id}/update`;
        return apiService.put(url, data);
    }

    /*******************Education End*****************************/

    /*******************Info*****************************/
    getInfoByEnrollment(enrollmentId) {
        this.setToken();
        let url = `${this.#api}enrollment/${enrollmentId}/info`;
        return apiService.get(url);
    }

    saveInfo(enrollmentId, data) {
        this.setToken();
        let url = `${this.#api}enrollment/${enrollmentId}/info`;
        return apiService.post(url, data);
    }

    /*******************Info End*****************************/

    /*******************Document*****************************/
    uploadDocument(enrollmentId, data) {
        this.setToken();
        let url = `${this.#api}enrollment/${enrollmentId}/upload-document`;
        return apiService.post(url, data);
    }

    saveInfo(enrollmentId, data) {
        this.setToken();
        let url = `${this.#api}enrollment/${enrollmentId}/info`;
        return apiService.post(url, data);
    }

    /*******************Document End*****************************/

    enrollmentStatus(enrollmentId) {
        this.setToken();
        let url = `${this.#api}enrollment/${enrollmentId}/status`;
        return apiService.get(url);
    }

    IsPreRegistrationActive() {
        this.setToken();
        let url = `${API_URL}api/admission/pre-registration-available`;
        return apiService.get(url);
    }

    saveAndExit(id, data) {
        let url = `${this.#api}enrollment/${id}/save-and-exit`;
        return apiService.post(url, data);
    }

    verifyEmail(data) {
        let url = `${this.#api}enrollment/verify/email`;
        return apiService.post(url, data);
    }


    getEntrances(preRegistrationId) {

        let url = `${this.#api}enrollment/${preRegistrationId}/get/entrances`
        return apiService.get(url);
    }
    getEntranceDetail(preRegistrationId,entranceId) {

        let url = `${this.#api}enrollment/${preRegistrationId}/get/entrance-detail/${entranceId}`
        return apiService.get(url);
    }

    payAtCollege(id,data){
        if(id){
            this.setToken2(id);
        }else{
            this.setToken();
        }
        let url = `${this.#api}enrollment/pay-at-college`
        return apiService.post(url, data);
    }
    esewa(enrollmentId, data) {
        let url = `${this.#api}enrollment/${enrollmentId}/esewa`;
        return apiService.post(url, data)
    }
    userEnrollmentLogin(data) {
        let url = `${API_URL}api/admission/pre-registration/user-enrollment/login`;
        return apiService.post(url, data)
    }
    requestEnrolmentCredentials(data){
        let url = `${API_URL}api/admission/pre-registration/user-enrollment/send-enrolment-credentials`;
        return apiService.post(url, data)
    }
    getUserEnrollmentDetail(enrollmentId) {
        let url = `${API_URL}api/admission/pre-registration/user-enrollment/${enrollmentId}/detail`;
        return apiService.get(url);
    }
    getUserEnrollmentResult(enrollmentId) {
        let url = `${API_URL}api/admission/pre-registration/${enrollmentId}/result`
        return apiService.get(url)
    }
    getPaymentDetailInformation(type){
        let url = `${API_URL}api/admission/pre-registration/payment/gateway/setting/${type}`;
        return apiService.get(url)
    }
    initiateEpayment(id, data){
        let url = `${API_URL}api/admission/pre-registration/user-enrollment/${id}/epayment/initiate`;
        return apiService.post(url, data)
    }
    verifyKhaltiPayment(id, data){
        let url = `${API_URL}api/admission/pre-registration/user-enrollment/${id}/verify/khalti-payment`;
        return apiService.post(url, data)
    }
    downloadUserEnrollmentAdmitCard(enrollmentId,data=null){

        let url = `${API_URL}api/admission/pre-registration/user-enrollment/${enrollmentId}/download/admit-card`;
        if (data != null && data != undefined && data != "") {
            var queryString = Object.keys(data).map((key) => {
                if (data[key] && data[key] != null)
                    return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }).join('&');
            url = url + '?' + queryString;
        }
        window.open(url);
    }
    downloadUserEnrollmentInvoice(enrollmentId,data=null){

        let url = `${API_URL}api/admission/pre-registration/user-enrollment/${enrollmentId}/download/invoice`;
        if (data != null && data != undefined && data != "") {
            var queryString = Object.keys(data).map((key) => {
                if (data[key] && data[key] != null)
                    return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }).join('&');
            url = url + '?' + queryString;
        }
        window.open(url);
    }
    downloadOfferLetter(enrollmentId,data=null){

        let url = `${API_URL}api/admission/pre-registration/user-enrollment/${enrollmentId}/download/offer-letter`;
        if (data != null && data != undefined && data != "") {
            var queryString = Object.keys(data).map((key) => {
                if (data[key] && data[key] != null)
                    return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }).join('&');
            url = url + '?' + queryString;
        }
        window.open(url);
    }
    getUserAllDocuments(enrollmentId){
        let url = `${API_URL}api/admission/pre-registration/user-enrollment/${enrollmentId}/get/all/document`;
        return apiService.get(url)
    }
    acceptEnrolmentOffer(enrollmentId,data={}){
        let url = `${API_URL}api/admission/pre-registration/user-enrollment/${enrollmentId}/accept-enrolment-offer`;
        return apiService.post(url,data)
    }

    getYears(){
        let url = `${API_URL}api/admission/get/np/years`
        return apiService.get(url)
    }

    getMonths()
    {
        let url = `${API_URL}api/admission/get/np/months`
        return apiService.get(url)
    }

    getDays()
    {
        let url = `${API_URL}api/admission/get/np/days`
        return apiService.get(url)
    }
    checkDuplicateEmail(data={}){
        let url = `${API_URL}api/admission/pre-registration/check-duplicate-email`;
        return apiService.post(url,data);
    }




}
